<template>
  <section class="success">
    <confirm :activation="activeConfirm" :message="message" v-if="activeConfirm" @oga="retourConfirm"/>
    <popup-base
        v-if="!activeConfirm"
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Réduction commerciale
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">

            <div class="text-left text-c12 mt-10">
              Type de réduction
            </div>
            <multiselect
                :option="allInfo"
                background="white"
                class="mt-2"
                width="36%"
                height="50px"
                placeholder="Sélectionnez un type"
                :value-t="allInfo[0]"
                @info="retourNom"
            />


            <div v-if="position">
              <div class="text-left text-c12 mt-4">
                {{position}} de la réduction
              </div>

              <div class="mt-2">
                <amount
                    v-if="position === allInfo[1]"
                    label="FCFA"
                    height="50px"
                    :value-t="0"
                  @info="retourAmount"
                />

                <inputo
                    v-if="position === allInfo[0]"
                    label="%"
                    type="number"
                    height="50px"
                    :value-t="0"
                    @info="retourPercentage"
                />
              </div>
            </div>

            <div class="mt-6 text-left text-c14">
             <div> Pourcentage: <span class="text-red font-c7"> {{ calculPercentage() }} % </span></div>
              <div class="mt-2">Montant: <span class="text-oho-blue font-c7"> {{ calculReduction() }} FCFA </span></div>
            </div>

            <div
              v-if="error !== null"
              class="mt-6 text-red text-c13"
            >
              {{ error }}
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <buton
                label="Enregistrer"
                radius="0px 0px 10px 10px"
                height="55px"
                :charge="charge"
                @oga="activeConfirm = true"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import ic_agence from '../../assets/icons/agenceGray.svg'
import fermer from '../../assets/icons/fermer.svg'
import multiselect from '../helper/form/multiselect'
import amount from '../helper/form/amount'
import inputo from '../helper/form/input'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import confirm from "./confirm"

export default {
  name: 'Success',
  components: { PopupBase, buton, inputo, multiselect, amount, confirm },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer
      },
      percentage: null,
      error: null,
      charge: false,
      allInfo: ['Pourcentage', 'Montant'],
      position: null,
      amount: null,
      activeConfirm: false,
      message: 'Êtes-vous sûr de vouloir appliquer cette réduction ?'
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    retourConfirm (answer) {
      if (answer) {
        this.continuer()
      }
      this.activeConfirm = false
    },

    calculReduction() {
      let amount = (this.donnes.financialData.totalAmount + this.donnes.financialData.deliveryAmount)-(this.donnes.financialData.supportAmount)
      console.log('amount')
      console.log(amount)
      let discount = 0
      if (this.position === this.allInfo[0]) {
        discount = (amount * parseFloat(this.percentage)) / 100
      } else {
        discount = this.amount
      }
      return discount
    },

    calculPercentage() {
      let amount = (this.donnes.financialData.totalAmount + this.donnes.financialData.deliveryAmount)-(this.donnes.financialData.supportAmount)
      let discount = this.calculReduction()
      let percent = 0
      if (this.position === this.allInfo[1]) {
        percent = (discount * 100) / amount
      } else {
        percent = this.percentage
      }
      return percent
    },

    continuer () {
      this.error = null
      if (this.position) {
        if (this.percentage || this.amount) {
          this.charge = true
          let discount = this.calculReduction()

          const body = {
            id: this.donnes.id,
            discount: discount,
            meta: this.donnes.meta,
            treatmentData: this.donnes.treatmentData
          }

          this.save(body)
        } else {
          this.charge = false
          this.error = 'Veuillez entrer la réduction'
        }
      } else {
        this.charge = false
        this.error = 'Veuillez selectionner le type de réduction'
      }
    },

    save (body) {
      http.put(apiroutes.baseURL + apiroutes.updateDevis, body)
          .then(() => {
            this.$emit('oga', true)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },

    retourPercentage (answer) {
      this.percentage = parseFloat(answer)
    },

    retourAmount(a) {
      this.amount = a
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },

    retourNom (answer) {
      this.position = answer
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px) {
      .cardinal {
        width: 90%;
      }
      .titre {
        font-size: 18px;
      }
      .button {
        width: 100%;
      }
    }
</style>
