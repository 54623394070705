<template>
  <div>
    <newClient :activation="activeClient" steep="devis" v-if="activeClient" @oga="retourClient"/>
    <mesure :activation="activeMesure" v-if="activeMesure" :donnes="devis" @info="infoMesure" @oga="retourMesure"/>
    <div class="flex">
      <div class="premier bloc bg-fb rounded-6">
        <div class="p-10">
          <div class="flex w-full">
            <div class="w-4/5" @click="activeDetail =! activeDetail">

              <div
                  v-if="charge && client.length === 0"
                  class="flex justify-center mt-16"
              >
                <easy-spinner
                    type="dots"
                    size="40"
                />
              </div>

              <multiselect
                  :clientSteep="true"
                  :writeOption="true"
                  :value-t="clientWitness"
                  placeholder="Client"
                  :option="sendClient"
                  @info="retourUser"
                  v-if="client.length > 0 && devis === null"
                  first="p"
                  height="50px"
                  width="70%"
                  background="white"
              />

              <div class="w-full flex items-center" v-if="devis !== null">
                <label-name :first="devis.client.name" :second="devis.client.surname" height="40px" width="40px"/>
                <div class="ml-4 text-c18 text-left w-4/5">
                  <div>{{ devis.client.name }} {{ devis.client.surname }} </div>
                  <div class="text-90 text-c16">
                    {{ devis.client.phone }} | {{ devis.client.city }}, {{ devis.client.address }}
                  </div>
                </div>
              </div>
            </div>

            <div class="w-1/5 ml-4" v-if="devis === null">
              <bouton
                label="Nouveau"
                radius="5px"
                size="16px"
                :icon="icons.add"
                @oga="activeClient = true"
              />
            </div>

            <div class="w-1/5" v-if="devis !== null && $store.getters.coupon !== null">
              <bouton
                  label="Vente 2ème paire"
                  height="23px"
                  color="#828282"
                  background="#F0F0F0"
                  radius="5px"
                  size="12px"
              />
            </div>
          </div>

          <div class="mt-6" v-if="valueClient !== null">
            <div class="flex items-center">
              <div class="text-c24 font-c6">
                Devis Nº {{devis.reference}}
              </div>
              <div class="ml-4 w-1/6">
                <bouton
                  label="En édition"
                  height="23px"
                  color="red"
                  background="#DDDDDD"
                  radius="5px"
                  size="12px"
                  v-if="devis.state === 'IN_PROGRESS'"
                />
              </div>
            </div>

            <div class="flex mt-6">
              <div class="w-1/2">
                <div class="inputo text-left flex items-center bg-white pl-4 text-c14">
                  <span>{{ startDate.toLocaleDateString() }}</span>
                </div>
                <div class="bg-fb text-90 text-c12 mouv rounded-5 w-1/3 ml-3">
                  Date du devis
                </div>
              </div>

              <div class="w-1/2 ml-6">
                <datepicker :value-t="expiration" :minDate="expiration" height="50px"/>
                <div class="bg-fb text-90 text-c12 mouv rounded-5 w-2/5 ml-3">
                  Date d’expiration
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="diviser" />

        <div class=" p-10"  v-if="valueClient !== null">
          <article-liste />

          <div class="mt-6">
            <medecin @medecin="retourMedecin" v-if="devis !== null && devis.panier !== undefined"/>
          </div>

          <div class="mt-4">
            <ordonnance-liste @note="retourNote" @doc="retourDoc" @ordonnance="retourOrdonnance"/>
          </div>
        </div>

        <div class="diviser" />

        <div class="p-10 pt-6 pb-6 text-c14 text-60 text-left" v-if="valueClient !== null">
          <span class="text-black font-c5">Agence: </span>  <span class="ml-2">OHO Les Lunettes, <span v-if="agence">{{ agence.substr(0, 1).toUpperCase() }}{{ agence.substr(1, agence.length) }}</span> </span>
          <span class="ml-6 text-black font-c5">Commercial(e): </span>  <span class="ml-2" v-if="users !== null && users !== undefined">{{ users.fullname }}</span>
        </div>
      </div>

      <div class="second ml-4">
        <div class="w-full">
          <div class="flex">
            <div class="w-2/5 mr-4">
              <bouton
                label="Aperçu"
                size="16px"
                weight="600"
                :icon="icons.eye"
                background="#EAEAEF"
                :charge="charge"
                color="black"
              />
            </div>

            <div class="w-3/5">
              <bouton
                label="Enregistrer"
                weight="600"
                size="16px"
                :charge="charge"
                @oga="saveDevis"
              />
            </div>
          </div>

          <div class="bloc bg-white rounded-6 p-6 mt-6 text-left">
            <div class="flex text-c16">
              <div class="text-90 w-1/2">Articles</div>
              <div class="w-1/2 font-c7 ml-4">
                <span v-if="devis !== null && devis.panier !== undefined">
                  {{devis.panier.length}}
                </span>
                <span v-if="devis === null">00</span>
              </div>
            </div>

            <div class="flex text-c16 mt-4">
              <div class="text-90 w-1/2">Montant total</div>
              <div class="w-1/2 font-c7 ml-4">{{amount}} Fr</div>
            </div>
          </div>

          <div class="bloc bg-white rounded-6 p-6 mt-6 text-left" v-if="devis !== null && devis.panier !== undefined">
            <div class="text-c16 text-90">
              Identifier un commercial externe
            </div>

            <div class="mt-4" v-if="commerciaux.length > 0">
              <multiselect
                  :icon="icons.user"
                  value-t=""
                  :writeOption="true"
                  width="85%"
                  :option="commerciaux"
                  background="white"
                  height="50px"
                  @info="retourCommercial"
              />
            </div>
          </div>

          <div v-if="devis">
            <bouton
                label="Mesures du client"
                size="16px"
                radius="4px"
                weight="400"
                color="#fff"
                height="59px"
                background="#FF8888"
                :icon="icons.edit"
                class="mt-6"
                @oga="activeMesure = true"
            />

            <div v-if="devis.clientMeasures">
              <detail-mesure :donnes="devis"/>
            </div>
          </div>

          <div class="flex justify-center mt-6" v-if="charge">
            <easy-spinner
                type="dots"
                size="40"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eye from '../../../assets/icons/eye.svg'
import bouton from '../../helper/add/button'
import add from '../../../assets/icons/add2.svg'
import edit from '../../../assets/icons/edit.svg'
import user from '../../../assets/icons/user-circle.svg'
import update from '../../../assets/icons/update.svg'
import articleListe from './articleListe'
import ordonnanceListe from './ordonnanceListe'
import newClient from '../../popup/newClient'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'
import multiselect from '../../helper/form/multiselect'
import datepicker from '../../helper/form/datePicker'
import labelName from '../../helper/add/nameLabel'
import medecin from './medecinSession'
import mesure from '../../popup/priseDeMesure'
import detailMesure from '../detailsDevis/mesure'

export default {
  name: "Index",

  components: {
    bouton,
    labelName,
    articleListe,
    ordonnanceListe,
    newClient,
    multiselect,
    datepicker,
    medecin,
    mesure,
    detailMesure
  },
  props: {
    donnes: {
      type: Object,
      default: null
    },
    coupon: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      icons: {
        eye,
        add,
        update,
        user,
        edit
      },
      client: [],
      valueClient: null,
      activeDetail: false,
      activeClient: false,
      charge: true,
      dataClient: [],
      expiration: null,
      startDate: null,
      devis: null,
      agence: null,
      users: null,
      details: null,
      note: '',
      commerciaux: [],
      comData: [],
      ordonnance: null,
      doc: null,
      ordonnanceUrl: '',
      docUrl: '',
      clientWitness: null,
      activeMesure: false
    }
  },

  computed: {
    amount: function () {
      let data = this.$store.getters.trackBasket
      let amount = 0
      if (data !== null) {
        for (let o in data) {
            amount = amount + (parseInt(data[o].prix) * parseInt(data[o].qte))
        }
      }
      return amount.toLocaleString()
    },

    sendClient: function () {
      return this.client
    }
  },

  created () {
    this.pageClient()
    this.allCommerciaux()
    this.expiration = new Date(new Date().getTime() + (14 * 24 * 60 * 60 * 1000))
    this.devis = this.$store.getters.trackQuote
    // console.log('panier')
    // console.log(this.devis)
    this.valueClient = this.devis
    this.startDate = new Date()
    if (this.devis !== null) {
      this.startDate = new Date(this.devis.created_at)
      this.expiration = new Date(this.devis.expireDate)
      this.charge = false
    }
    const user = this.$store.getters.userForDevis
    if (user !== null) {
      this.charge = false
      this.clientWitness = user.name + ' ' + user.surname
      if (this.devis === null){
        this.client = [this.clientWitness]
        this.saveEstimate(user)
      }
    }
  },

  mounted(){
    this.agence = this.$store.getters.trackAgence.toLowerCase().replace('agence', '')
    if (this.agence.substr(0, 1) === ' '){
      this.agence = this.agence.substr(1, this.agence.length)
    }
    if (this.$store.getters.token !== null){
      this.users = this.parseJwt(this.$store.getters.token)
    }
  },

  methods: {
    retourMesure () {
      this.activeMesure = false
    },

    infoMesure (answer) {
      this.devis.clientMeasures = answer
      this.$store.dispatch('saveQuote', this.devis)
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    },

    retourOrdonnance (answer) {
      this.ordonnance = answer
    },

    retourDoc (answer) {
      this.doc = answer
    },

    retourCommercial (answer) {
      for (let i in this.comData) {
        if (this.comData[i].fullname === answer) {
          this.saveCommerciaux(this.comData[i].id)
        }
      }
    },

    retourMedecin (answer) {
      this.saveCommerciaux(answer)
    },

    allClient (name) {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.filtreClient, {
        name: name
      })
          .then(response => {
            this.charge = false
            console.log('filtre success')
            console.log(response)
            this.dataClient = response
            const tab = []
            for (let item in response) {
              tab.push(response[item].name + ' ' + response[item].surname)
            }
            this.client = tab
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    pageClient () {
      http.get(apiroutes.baseURL + apiroutes.getClient + '?page=1&limit=30')
          .then(response => {
            console.log('page success')
            console.log(response)
            this.charge = false
            this.dataClient = response.data
            const tab = []
            for (let item in response.data) {
              tab.push(response.data[item].name + ' ' + response.data[item].surname)
            }
            this.client = tab
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    saveCommerciaux (id) {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.addDoctor, {
        id: id,
        estimate: this.devis.id
      })
          .then(response => {
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    allCommerciaux () {
      http.get(apiroutes.baseURL + apiroutes.getCommerciaux + '?type=COMMERCIAL')
          .then(response => {
            this.comData = response
            const tab = []
            for (let item in response) {
              tab.push(response[item].fullname)
            }
            this.commerciaux = tab
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourUser (answer) {
      if (answer.length > 3) {
        this.allClient(answer)
      }

      for (let item in this.dataClient) {
        if (this.dataClient[item].name + ' ' + this.dataClient[item].surname === answer) {
         const body = this.dataClient[item]
          this.saveEstimate(body)
          this.$store.dispatch('saveDevisUser', null)
        }
      }
    },

    saveEstimate (body) {
      const obj = {
        "client": body.id,
        "expireDate": this.expiration,
        "meta": {}
      }

      const coupon = this.$store.getters.coupon

      if (coupon !== null) {
        console.log('le coupon est pris')
        obj.coupon = coupon.code ? coupon.code : coupon.coupon
        obj.meta.couponUse = coupon
      }

      http.post(apiroutes.baseURL + apiroutes.createEstimate, obj)
          .then(response => {
            this.valueClient = response
            this.devis = response
            this.$store.dispatch('saveQuote', response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    async saveDevis() {
      this.charge = true

      let tab =[]
      for (let x in this.devis.panier) {
        let body = {
          estimate: this.devis.id,
          quantity: 1,
          product: "",
          amount: parseInt(this.devis.panier[x].prix),
          meta: {
            treatmentData: null,
            oeil: null
          }
        }

        if (this.devis.panier[x].product !== undefined) {
          body.product = this.devis.panier[x].product.id
        } else {
          body.product = this.devis.panier[x].id
        }

        if (this.devis.panier[x].qte !== undefined) {
          body.quantity = parseInt(this.devis.panier[x].qte)
        }

        if (this.devis.panier[x].details !== undefined) {
          body.meta.treatmentData = this.devis.panier[x].details
        }
        if (this.devis.panier[x].oeil !== undefined) {
          body.meta.oeil = this.devis.panier[x].oeil
        }

        tab.push(body)
      }

      if (tab.length === this.devis.panier.length){
        this.charge = true

        // ENREGISTREMENT DES FICHIERS
        if (this.doc !== null) {
          await this.saveFile(this.doc, 'DOC')
        }

        if (this.ordonnance !== null) {
          await this.saveFile(this.ordonnance, 'ORDONNANCE')
        }
        if (this.ordonnanceUrl !== '' || this.docUrl !== '') {
          this.addFileToEstimate()
        }

        // lorsqu'il y a de details de traitement ou note
         this.updateEstimation()

        // enregistrement des articles sur le devis
         this.addAllArticle(tab)
      }
    },

    //Ici j'enregistre les fichiers et je récupère des url
    async saveFile (file, type) {
      this.charge = true
      let data = new FormData()
      data.append('files', file)
      let config = {
        header : {
          'Content-Type' : 'multipart/form-data'
        }
      }

      await http.post(apiroutes.baseURL + apiroutes.upload, data, config)
          .then(response => {
            if (type === 'DOC') {
              this.docUrl = response[0].url
            } else {
              this.ordonnanceUrl = response[0].url
            }
          })
          .catch(error => {
            console.log(error)
          })
    },

    //Ici j'ajoute les articles à l'estimation
    addAllArticle (body) {
      http.post(apiroutes.baseURL + apiroutes.addArticle, {
        "articles": body
      })
          .then(response => {
            console.log('article add ok')
            // console.log(response)
            this.charge = false
            this.$router.push({ path: '/detailsDevis', query: { x: response.id } })
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    //Ici j'ajoute les fichiers à l'estimation
    addFileToEstimate () {
      let body = {
        id: this.devis.id
      }
      if (this.docUrl !== ''){
        body.documentUrls = [this.docUrl]
      }
      if (this.ordonnanceUrl !== ''){
        body.ordinanceUrls = [this.ordonnanceUrl]
      }
      http.post(apiroutes.baseURL + apiroutes.mesure, body)
          .then(response => {
            console.log('success file')
            console.log(response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourNote (answer) {
      this.note = answer
    },

    updateEstimation () {
      const body = {
        id: this.devis.id,
        treatmentData: null,
        meta: {
          note: this.note
        },
      }

      const coupon = this.$store.getters.coupon

      if (coupon !== null) {
        console.log('le coupon est pris')
        body.coupon =  coupon.code ? coupon.code : coupon.coupon
        body.meta.couponUse = coupon
      }

      http.put(apiroutes.baseURL + apiroutes.updateEstimate, body)
          .then(response => {
            console.log('save config')
            console.log(response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
          })
    },

    retourClient (answer) {
      if (answer){
        console.log(answer)
      }
      this.activeClient = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.svg-icon {
  fill: white;
}
.diviser{
  height: 2px;
  background-color: #DCDCE4;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
.mouv{
  margin-top: -57px;
  margin-bottom: 60px;
}
.premier {
  width: 70%;
}
.second {
  width: 30%;
}

.inputo{
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  height: 50px;
  border-radius: 5px;
}
.inputo:focus-within {
  border: 1px solid $hover-color!important;
}
.inputo:hover {
  border: 1px solid $hover-color!important;
}
</style>
