<template>
  <div class="text-left">
    <div
      class="flex tete p-6 text-c5 text-c14"
      @click="activePosition = -1"
    >
      <div class="w-1/2">
        Articles
      </div>
      <div class="w-1/2 flex">
        <div class="w-1/5 ml-4">
          Qté
        </div>
        <div class="w-1/3 ml-4">
          Prix unitaire
        </div>
        <div class="w-1/3 ml-4">
          Prix total
        </div>
      </div>
    </div>

    <div class="diviser mt-2" />

    <div
      v-if="devis !== null && devis.panier !== undefined"
      class="mt-6"
    >
      <div
        class="w-full flex mt-6"
        v-for="(i, index) in devis.panier"
        :key="index"
        @click="open(index)"
      >
        <div class="flex w-1/2">
          <icon
            v-if="i.type === undefined"
            :data="icons.verre"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            original
          />
          <icon
            v-if="i.type === 'FRAMES'"
            :data="icons.monture"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            original
          />
          <icon
            v-if="i.type === 'ACCESSORY'"
            :data="icons.etuie"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            original
          />
          <icon
            v-if="i.type === 'SERVICE'"
            :data="icons.prestation"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            original
          />

          <icon
            v-if="i.type === 'TREATMENT'"
            :data="icons.traitement"
            height="40"
            width="40"
            class="mr-2 cursor-pointer"
            original
          />

          <div
            v-if="i.type === undefined"
            class="ml-4 w-4/5"
          >
            <div class="text-c14 font-c5">
              {{ i.product.glassType }} | {{ i.product.ref }}
            </div>
            <div>
              <span class="font-c7">{{ i.oeil }}: </span>
              <span
                class="font-c4 text-c11 text-90 ml-2"
                v-if="i.details !== null"
              >
                Mat = {{ i.product.glassMaterial }}; Indice = {{ i.product.indice }}; S/couche = {{ i.details.sousCouche }};
                T.Dur = {{ i.details.dur }}; T.AR = {{ i.details.ar }};
                Sph = {{ i.details.sphere }}; Cyl = {{ i.details.cylindre }}; Add = {{ i.details.addition }};
                Axe = {{ i.details.axe }}°; Diamètre = {{ i.details.diametre }};
                <span v-if="i.details.color !== undefined && i.details.color !== ''">Couleur = {{ i.details.color }}</span>
              </span>
            </div>
          </div>

          <div
            v-if="i.type !== undefined"
            class="ml-4 w-4/5"
          >
            <div class="text-c14 font-c5">
              {{ i.name }}
            </div>
          </div>
        </div>

        <div class="w-1/2 flex">
          <div class="w-1/5 ml-4">
            <div class="w-3/4">
              <bouton
                v-if="i.qte !== undefined"
                :label="'X' + i.qte"
                size="12px"
                height="35px"
                color="#828282"
                background="#F5F5F5"
              />
              <bouton
                v-if="i.qte === undefined"
                label="X 1"
                size="12px"
                height="35px"
                color="#828282"
                background="#F5F5F5"
              />

              <selectFiltre
                :option="optionQte"
                @info="retourQte"
                value=""
                size="12px"
                v-if="activePosition === index"
              />
            </div>
          </div>

          <div class="w-1/3">
            <bouton
              v-if="i.prix.amount === undefined"
              :label="i.prix.toLocaleString()"
              size="14px"
              height="35px"
              color="#000"
              background="#F5F5F5"
            />
            <bouton
              v-if="i.prix.amount !== undefined"
              :label="i.prix.amount.toLocaleString()"
              size="14px"
              height="35px"
              color="#000"
              background="#F5F5F5"
            />
          </div>

          <div class="w-1/3 ml-4">
            <bouton
              :label="calcule(i)"
              size="14px"
              height="35px"
              color="#000"
              background="#F5F5F5"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="devis === null || devis.panier === undefined"
      class="mt-20"
    >
      <bouton
        label="Ajouter un article"
        border="0.5px dashed #5138EE"
        size="14px"
        color="#5138EE"
        background="#EFF4FF"
        @oga="retourAdd"
      />
    </div>

    <diviser
      height="1.8px"
      color="#5138EE"
      class="mt-10"
    />
  </div>
</template>

<script>
import bouton from '../../helper/add/button'
import diviser from '../../helper/add/divider'
import verre from '../../../assets/icons/verre.svg'
import traitement from '../../../assets/icons/surface.svg'
import prestation from '../../../assets/icons/prestation.svg'
import monture from '../../../assets/icons/monture.svg'
import etuie from '../../../assets/icons/etuie.svg'
import selectFiltre from '../../helper/form/selectFiltre'

export default {
  name: "Index",

  components: {
    bouton,
    diviser,
    selectFiltre
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        verre,
        monture,
        etuie,
        prestation,
        traitement
      },
      devis: null,
      optionQte: ['X 1', 'X 2', 'X 3', 'X 4', 'X 5'],
      activePosition: -1
    }
  },

  created () {
    this.devis = this.$store.getters.trackQuote
  },

  methods: {
    retourAdd () {
      this.$router.push('/panier')
    },

    retourQte (answer) {
      this.devis.panier[this.activePosition].qte = answer.replace('X ', '')
      this.$store.dispatch('saveQuote', this.devis)

      this.open(this.activePosition)
    },

    open (index) {
      // if (this.activePosition !== index) {
      //   this.activePosition = index
      // } else {
      //   this.activePosition = -1
      // }
      console.log(index)
    },

    calcule (u) {
      if (u.qte === undefined) {
        if (u.prix.amount !== undefined) {
          return u.prix.amount.toLocaleString()
        } else {
          return u.prix.toLocaleString()
        }
      } else {
        let amount = 0
        if (u.prix.amount !== undefined) {
          amount = amount + (parseInt(u.prix.amount) * parseInt(u.qte))
        } else {
          amount = amount + (parseInt(u.prix)* parseInt(u.qte))
        }
        return amount.toLocaleString()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.diviser{
  height: 1px;
  background-color: #DCDCE4;
}
.tete{
  color: #5138EE;
  background: linear-gradient(180deg, #EFF4FF 0%, rgba(239, 244, 255, 0) 100%);
  border-radius: 10px;
  border-top: 5px solid #5138EE;
}

</style>
