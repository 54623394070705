<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full p-10"
        >
          <div class="text-center text-c18 font-c4 mt-6">
            <div class="flex justify-center">
              <icon
                  :data="icons.coupon"
                  height="70"
                  width="70"
                  class="cursor-pointer"
                  original
              />
            </div>


            <div class="text-c24 mt-6">
              Utiliser le coupon
            </div>

            <inputo @info="retourCode" height="73px" size="24px" placeholder="OHO-8783973" class="mt-6"/>

            <div class="mt-6 bloc p-4" v-if="valueCoupon !== null">
              <div class="flex">
                <icon
                    :data="icons.user"
                    height="30"
                    width="30"
                    class="cursor-pointer"
                    original
                />
                <div class="w-5/6 ml-4 text-c14">
                  <div class=" text-60 text-left">
                    {{ valueCoupon.order.estimate.client.name }}  {{ valueCoupon.order.estimate.client.surname }}
                  </div>

                  <diviser height="0.5px" color="#DDDDDD" class="mt-2"/>

                  <div class="text-left mt-4"><span class="text-60 ">Commande originale :</span> {{ valueCoupon.order.reference }}</div>
                </div>

              </div>
            </div>
          </div>

          <div v-if="error !== null" class="text-red text-c14 mt-6">{{error}}</div>
          <div class="mt-12 flex justify-center">

            <div class="w-1/2">
              <buton
                  label="Continuer"
                  size="16px"
                  weight="600"
                  radius="5px"
                  :charge="charge"
                  @oga="continuer"
              />
            </div>
          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import buton from '../helper/add/button'
import coupon from '../../assets/icons/coupon.svg'
import user from '../../assets/icons/user-circle.svg'
import fermer from '../../assets/icons/fermer.svg'
import inputo from '../helper/form/input'
import diviser from '../helper/add/divider'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"

export default {
  name: 'Success',
  components: { PopupBase, buton, inputo, diviser },
  props: {
    activation: {
      type: Boolean,
      default: false
    },
    coupon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        coupon,
        fermer,
        user
      },
      error: null,
      code: null,
      valueCoupon: null,
      charge: false
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
    let coupon = this.$store.getters.coupon
    if (coupon !== null) {
      this.valueCoupon = coupon
    }
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },

    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    },

    retourCode (answer) {
      this.code = answer
      if (answer.length > 10){
        this.trackCoupon()
      }
    },

    continuer () {
      console.log(this.valueCoupon)
      if (this.valueCoupon !== null) {
        this.$store.dispatch('saveCoupon', this.valueCoupon)
        this.$emit('oga', this.valueCoupon)
      }
    },

    trackCoupon () {
      this.charge = true
      this.error = null
      this.valueCoupon = null
      http.get(apiroutes.baseURL + apiroutes.checkCoupon +'?coupon='+ this.code)
          .then(response => {
            this.valueCoupon = response
            this.charge = false
            console.log(response)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.status === 404) {
              this.error = "Ce coupon n'existe pas"
            }
            if (error.status === 409) {
              this.error = "Ce coupon est déjà utilisé"
            }
          })
    }

  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 42%;
    }

    .bloc{
      background: #FFFFFF;
      border: 0.5px solid #DDDDDD;
      box-sizing: border-box;
      border-radius: 5px;
    }
    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
