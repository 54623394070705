<template>
  <div class="p-10 pr-16">
    <coupon :activation="activeCoupon" v-if="activeCoupon" @oga="retourCoupon"/>
    <alert :activation="activeAlert" v-if="activeAlert" @oga="retourAlert"/>

    <div>
      <div class="">
        <div class="w-2/3 flex text-left text-c34 items-center font-c7">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
            @click="retourBack"
          />
          <div class="ml-4">
            Nouveau devis
          </div>
        </div>
      </div>

      <div class="mt-6 flex ml-4">
        <div class="w-1/4">
          <div class="bloc bg-white rounded-6 p-5">
            <div class="alert p-4 pb-10 text-left text-c14">
              Veuillez remplir les différents champs pour rédiger votre proposition de devis à soumettre au client
            </div>
          </div>

          <bouton
              v-if="devis === null && !codeCoupon"
            label="Vente 2ème paire"
            class="mt-6"
            weight="600"
            size="16px"
            @oga="activeCoupon = true"
          />

<!--          <bouton-->
<!--              label="Vente 2ème paire"-->
<!--              background="#C4C4C4"-->
<!--              color="#fff"-->
<!--              class="mt-6"-->
<!--              weight="600"-->
<!--              size="16px"-->
<!--              :charge="charge"-->
<!--          />-->
        </div>

        <div class="w-4/5 ml-3">
          <new-devis :coupon="codeCoupon"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import add from '../../assets/icons/add.svg'
import newDevis from '../../component/cdevs/createDevis/newDevis'
import coupon from '../../component/popup/useCoupon'
import alert from '../../component/popup/deleteDevis'
import apiroutes from '../../router/api-routes'
import http from '../../plugins/https'

export default {
  name: "Index",

  components: {
    bouton,
    newDevis,
    coupon,
    alert
  },

  data () {
    return {
      icons: {
        back,
        add
      },
      activeCoupon: false,
      codeCoupon: null,
      devis: null,
      charge: false,
      activeAlert: false
    }
  },

  computed: {
  },

  created () {
    this.devis = this.$store.getters.trackQuote
    let coupon = this.$store.getters.coupon
    this.$store.dispatch('saveUpdate', null)
    if (coupon !== null) {
      this.codeCoupon = coupon
    }
  },

  methods: {
    retourBack () {
      const panier = this.$store.getters.trackBasket

      if (panier.length === 0) {
        this.activeAlert = true
      } else {
        window.history.back()
      }
    },

    retourCoupon (answer) {
      if (answer) {
        this.codeCoupon = answer.code
      }
      this.activeCoupon = false
    },

    deleteRequest () {
      const devis = this.$store.getters.trackQuote

      this.charge = true

      if (devis !== null){
        http.post(apiroutes.baseURL + apiroutes.deleteEstimate, {
          id: devis.id,
        })
            .then(response => {
              console.log('success delete ' + response)
              window.history.back()
            })
            .catch(error => {
              this.charge = false
              window.history.back()
              console.log(error)
            })
      } else {
        window.history.back()
      }

    },

    retourAlert (answer) {
      if (answer !== false) {
        this.deleteRequest()
      } else {
        this.activeAlert = false
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.bloc {
  filter: drop-shadow(0px 4.08108px 10px rgba(0, 0, 0, 0.1));
}
.alert {
  background: #FFF0F0;
  border-radius: 5.10135px;
}
</style>
