<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto p-5">
        <div
          class="success-container h-full pt-6"
        >
          <div class="text-left flex pl-6 pr-6">
            <div
              class="text-c24 font-c7 w-4/5"
            >
              Veuillez sélectionnez l’assureur
            </div>
            <div class="w-1/5 flex justify-end">
              <icon
                :data="icons.fermer"
                height="30"
                width="30"
                class="cursor-pointer"
                original
                @click="fermer"
              />
            </div>
          </div>

          <div class="text-c18 font-c4 mt-6 bg-white p-6 pb-12">
            <div class="w-full mt-6">
              <inputo :icon="icons.search" @info="retourSearch" placeholder="Rechercher..." />
            </div>

            <div class="flex justify-center">
              <easy-spinner
                  v-if="charge"
                  type="dots"
                  size="40"
              />
            </div>


            <div class="mt-6 orther">
              <div
                  v-for="(item, index) in tracker"
                  :key="index"
                  class="flex items-center mt-4 cursor-pointer item cursor-pointer"
                  @click="selected(item)"
              >
                <icon
                    :data="icons.ic_agence"
                    height="60"
                    width="60"
                    class="cursor-pointer"
                    original
                    @click="fermer"
                />
                <div class="w-4/5 text-left ml-5">
                  <div class="text-c16 font-c6">{{ item.name }}</div>
                  <div class="text-c16">{{ item.location }}</div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'
import ic_agence from '../../assets/icons/agency.svg'
import fermer from '../../assets/icons/fermer.svg'
import search from '../../assets/icons/search.svg'
import http from "../../plugins/https"
import apiroutes from "../../router/api-routes"
import inputo from '../helper/form/input'

export default {
  name: 'Success',
  components: {
    PopupBase,
    inputo
  },

  props: {
    activation: {
      type: Boolean,
      default: false
    },
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      wantToMakeOffer: false,
      icons: {
        ic_agence,
        fermer,
        search
      },
      error: null,
      charge: true,
      allInfo: [],
      recherche: '',
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.allInfo

      if (this.recherche !== null && this.recherche !== '') {
        const type = this.recherche.toLowerCase()
        retour = retour.filter((item) => {
          if (item.name && item.location) {
            if ((item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                (item.location.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
              return item
            }
          }
        })
      }

      return retour
    }
  },
  created() {
    this.wantToMakeOffer = this.activation
    this.getCompany()
  },

  methods: {
    selected (item) {
      this.$emit('oga', item)
    },

    retourSearch (answer) {
      this.recherche = answer
    },

    fermer () {
      this.$emit('oga', false)
    },

    getCompany () {
      http.get(apiroutes.baseURL + apiroutes.allCompany)
          .then(response => {
            this.allInfo = response
            console.log(response)
            this.charge = false
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            this.error = "Problème de connexion. Veuillez ressayer"
          })
    },


    logState (answer) {
      this.wantToMakeOffer = answer
      this.fermer()
    },
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success {
      z-index: 999;
    }
    .cardinal {
      width: 50%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: #F5F5F5;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }

    .item:hover {
      color: $oho-blue;
    }
    .orther{
      max-height: 400px;
      overflow-y: scroll;
    }
    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre {
        font-size: 18px;
      }
      .bloc1 {
        width: 100%;
      }
      .bloc2 {
        width: 100%;
        font-size: 18px;
      }
      .button {
        width: 100%;
        font-size: 16px;
      }
      .cardinal {
        width: 100%;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }
</style>
