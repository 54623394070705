<template>
  <div class="text-left">
    <div class="flex">
      <div class="w-1/2">
        <upload
          :icon="icons.ordonnance"
          background="#FBFBFB"
          border="1px dashed #C4C4C4"
          radius="5px"
          label="Ajouter une ordonnance"
          color="#606060"
          @info="retourOrdonnance"
        />
      </div>

      <div class="w-1/2 ml-6">
        <upload
          :icon="icons.document"
          background="#FBFBFB"
          border="1px dashed #C4C4C4"
          radius="5px"
          label="Ajouter un document"
          color="#606060"
          @info="retourDoc"
        />
      </div>
    </div>

    <div class="mt-10">
      <textare
        placeholder="Laisser une note..."
        background="#F5F5F5"
        radius="5px"
        height="106px"
        @info="retourNote"
      />
    </div>
  </div>
</template>

<script>
import document from '../../../assets/icons/document.svg'
import ordonnance from '../../../assets/icons/ordonnance.svg'
import upload from '../../helper/form/uploadTemplate'
import textare from '../../helper/form/textarea'

export default {
  name: "Index",

  components: {
    upload,
    textare
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        document,
        ordonnance
      }
    }
  },

  created () {
  },

  methods: {
    retourNote (answer) {
      this.$emit('note', answer)
    },
    retourOrdonnance (answer) {
      this.$emit('ordonnance', answer)
    },
    retourDoc (answer) {
      this.$emit('doc', answer)
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}


</style>
