<template>
  <div
    class=""
  >
    <switches
      v-model="enabled"
      :color="couleur"
      @click="mouv"
    />
  </div>
</template>

<script>
import Switches from 'vue-switches'
export default {
  name: 'Index',
  components: {
    Switches
  },

  props: {
    deseable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: '40px'
    },
    border: {
      type: String,
      default: '1px solid #DCDCE4'
    },
    radius: {
      type: String,
      default: '4px'
    },
    background: {
      type: String,
      default: 'white'
    }
  },

  data () {
    return {
      enabled: false,
      couleur: 'default'
    }
  },
  methods: {
    mouv () {
      this.enabled =! this.enabled
      if (this.enabled === true){
        this.couleur = 'green'
      } else {
        this.couleur = 'default'
      }
      this.$emit('info', this.enabled)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";

</style>
