<template>
  <div
    class="inputo p-1 text-c14"
    :class="{
      error: error,
      success: success === true,
      deseable: deseable === true,
    }"
    :style="{
      height: height,
      border: border,
      'border-radius': radius,
      'background-color': background,
    }"
  >
    <div v-if="!deseable" class="flex h-full items-center">
      <icon
        v-if="icon !== null"
        :data="icon"
        height="25"
        width="25"
        class="icone cursor-pointer ml-2"
        original
      />

      <input
        v-model="fValue"
        :maxlength="maxLength"
        type="text"
        class="w-full h-full pl-3 pr-3"
        :placeholder="placeholder"
        :style="{ 'font-size': size, 'padding': padding, 'font-weight': weight }"
        @keypress="isNumberOnly"
      />

      <div class="md:w-1/6 w-2/5 flex justify-end" v-if="label !== null">
        <div
          class="lab mr-4 p-2 pl-4 pr-4 flex text-90 items-center justify-center w-auto"
        >
          <div>{{ label }}</div>
        </div>
      </div>
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "amount",
  components: {},

  props: {
    deseable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: null,
    },
    icon: {
      type: Object,
      default: null,
    },
    valueT: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "14px",
    },
    weight: {
      type: String,
      default: "400",
    },
    height: {
      type: String,
      default: "40px",
    },
    border: {
      type: String,
      default: '1px solid #DCDCE4',
    },
    radius: {
      type: String,
      default: "4px",
    },
    background: {
      type: String,
      default: "white",
    },
    maxLength: {
      type: Number,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    padding: {
      type: String,
      default: '0px 7px 0px 7px',
    },
  },

  data() {
    return {
      error: null,
      success: false,
      value: "",
      temoin: ""
    };
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.temoin !== this.value) {
        if (
            this.isRequired === true &&
            this.value.length === 0 &&
            this.value.length !== ""
        ) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.error = "email";
        }

        if (
            this.isRequired === true &&
            this.value >= 0 &&
            this.value !== ""
        ) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.success = true;
        }

        if (!this.name) {
          this.$emit("info", this.value);
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.temoin = this.value
        } else {

          this.$emit("oga", this.value, this.name);
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.temoin = this.value
        }
      }
    },

    // eslint-disable-next-line vue/return-in-computed-property
    fValue: {
      // getter
      // eslint-disable-next-line vue/return-in-computed-property
      get: function () {
        if (this.value !== "") {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          return this.formatUSD(this.value);
        }
      },
      // setter
      // eslint-disable-next-line vue/return-in-computed-property
      set: function (newValue) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.value = this.parseUSD(newValue);
      },
    },
  },

  created() {
    this.value = this.valueT;
    this.temoin = this.value
  },

  methods: {
    isNumberOnly($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    formatUSD(num) {
      return Number(num)
        .toString()
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    },

    parseUSD(text) {
      return Number(text.replace("$", "").replace(/,/g, ""));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/sass/variables.scss";
.inputo {
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  font-family: $font-default;
}
input {
  color: #0e0e0e;
  background-color: transparent;
  border-radius: 20px;
}
.inputo:focus-within {
  border: 1px solid $hover-color !important;
}
.inputo:hover {
  border: 1px solid $hover-color !important;
}
.error {
  border: 1px solid #ea9f9f !important;
}
.success {
  border: 1px solid rgba(93, 56, 224, 0.3) !important;
}
.lab {
  background: #f2f2f2;
  border-radius: 10px;
  height: 35px;
  font-size: 14px;
  line-height: 27px;
}
.deseable {
  background: #eeeeee;
}

@media screen and (max-width: 769px) and (min-width: 701px) {
  .inputo {
    height: 65px;
  }
  input {
    font-size: 18px;
  }
}

@media screen and (max-width: 700px) {
  .inputo {
    height: 55px;
  }
  input {
    font-size: 14px;
  }
}
</style>
